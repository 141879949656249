<script setup>
import {useBaseStore} from "~/stores/base";
import {userLoginThird} from "~/api/api.user";
import {Loading} from "@element-plus/icons-vue";

const router = useRouter()
const route = useRoute()

/** emits **/
const emits = defineEmits([])

/** props **/
const props = defineProps({})

/** data **/

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const userInfo = computed(() => useBaseStore().getterUserInfo)
const isLoading = computed(() => route.hash === '#apple-processing')
const hash = computed(() => route.hash)

/** watch **/

/** methods **/
const handleClick = () => {
  router.replace({
    path: router.currentRoute.value.path,
    query: router.currentRoute.value.query, // 保留查询参数
    hash: '#apple-processing'
  });
  useFirebase().appleLoginRedirect()
}

/** lifecycle **/
onMounted(async () => {
  if (hash.value === "#apple-processing") {
    try {
      const res = await useFirebase().appleLoginRedirectResult()
      console.log("getRedirectResult", res)
      // 刚刚三方登录跳转回来
      if (res) {
        const resLogin = await userLoginThird({thirdToken: res._tokenResponse.idToken, platform: LOGIN_CHANNEL.APPLE})
        console.log("userLoginThird", resLogin)
      }
    } catch (e) {
      console.error(e)
    }
    // 还是要主动关闭loading，虽然userLoginThird的message操作会导致进不到这个流程，但当拒绝授权登录而返回该页面时，需要能隐藏loading
    await router.replace({
      path: router.currentRoute.value.path,
      query: router.currentRoute.value.query, // 保留查询参数
      hash: ''
    });
  }
})

</script>

<template>
  <div class="firebase-apple-mobile" v-if="isMobile">
    <div class="btn-secondary-small" @click="handleClick">
      <img src="@/assets/img/icon-apple.svg" alt="apple">
      <span>continue with apple</span>
    </div>
  </div>
  <div class="firebase-apple-desktop" v-else>
    <div class="btn-secondary-small" @click="handleClick">
      <img src="@/assets/img/icon-apple.svg" alt="apple">
      <span>continue with apple</span>
    </div>
  </div>
  <div class="cover-loading" v-if="isLoading">
    <el-icon class="is-loading" color="#292929" size="large">
      <Loading/>
    </el-icon>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.firebase-apple-mobile {
}

.firebase-apple-desktop {
}
</style>
